import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { Transaction } from '@elastic/apm-rum';
import { firstValueFrom, fromEvent } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { OrderformFascade } from '../fascades/orderform.fascade';
import { generateUUID } from '@ds24/ds-generic-api';

const MONITORING_ERROR_INCLUDE_LIST = ['unzer', 'digistore24', 'svencart', 'stripe'];
const MONITORING_ERROR_EXCLUDE_LIST = ['https://', 'safari-extension', 'chrome-extension'];

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  constructor(private _elastic: ApmService, private _fascade: OrderformFascade) {}

  async initialize() {
    // @ts-ignore
    const ELASTIC_CONFIG = window.ELASTIC_CONFIG;

    if (
      ELASTIC_CONFIG &&
      ELASTIC_CONFIG.elasticApmServiceName !== '' &&
      ELASTIC_CONFIG.elasticApmServiceName.slice(0, 1) !== '$'
    ) {
      this._elastic.init({
        serviceName: ELASTIC_CONFIG.elasticApmServiceName,
        serverUrl: ELASTIC_CONFIG.elasticApmServerUrl,
        serviceVersion: ELASTIC_CONFIG.elasticApmServiceVersion,
        environment: ELASTIC_CONFIG.elasticApmEnvironment,
        disableInstrumentations: ['click'],
        flushInterval: 200,
      });
      this._elastic.apm.addLabels({
        'user_agent.device.width': window.innerWidth,
      });
      if (window.location.pathname.startsWith('/product')) {
        this._elastic.apm.addLabels({ orderform: 'PGB' });
        this._elastic.apm.addLabels({
          hasParentFrame: window.parent !== window,
        });
      } else {
        this._elastic.apm.addLabels({ renew: 'PGB' });
      }

      this._elastic.apm.addFilter(function (payload) {
        if (payload.errors) {
          payload.errors = payload.errors.filter(function (error) {
            if (MONITORING_ERROR_INCLUDE_LIST.filter((subject) => error.culprit.indexOf(subject) > -1).length > 0) {
              return true;
            }
            if (MONITORING_ERROR_EXCLUDE_LIST.filter((subject) => error.culprit.indexOf(subject) > -1).length > 0) {
              return false;
            }
            return true;
          });
        }
        return payload;
      });

      const affiliate = await firstValueFrom(this._fascade.affiliateName$);
      const vendor = await firstValueFrom(this._fascade.merchantName$);
      const orderformCustomerToken = generateUUID();
      this.addLabels({ affiliate, vendor, orderformCustomerToken });

      if (window.location.pathname.indexOf('payment_failed') >= 0) {
        const errorMessages = await firstValueFrom(this._fascade.messages$.pipe(map((messages) => messages.errors)));
        if (errorMessages.length > 0) {
          const orderPaymentRenderer = await firstValueFrom(
            this._fascade.order$.pipe(map((order) => order.order.payment_provider.renderer_type))
          );
          this.addLabels({ orderPaymentRenderer });
          this.trackUserInteraction('BuyAtProviderFailed', true);
          errorMessages.forEach((message) => this.trackValidationError(message, 'backend', 'payment-provider'));
        }
      }
    }
  }

  public addLabels(labels: Labels) {
    this._elastic.apm.addLabels(labels);
  }

  public trackUserInteraction(event: string, autoEnd: boolean): Transaction {
    const interaction = this._elastic.apm.startTransaction(event, 'user-interaction', {
      startTime: performance.now() - 10,
    });
    if (autoEnd) {
      interaction.end();
    }
    return interaction;
  }

  public trackValidationError(name: string, source: 'backend' | 'frontend', component: string) {
    if (!this.isActive()) {
      return;
    }
    this._elastic.apm.captureError({
      name: 'OrderFormValidationError',
      message: [source, component, name].join(':'),
    });
  }

  public isActive() {
    return this._elastic?.apm.isActive();
  }
}
