import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function formMarkAllAsTouched(form: UntypedFormGroup | UntypedFormArray) {
  Object.keys(form.controls).forEach((field) => {
    const control: AbstractControl = form.controls[field];
    if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
      formMarkAllAsTouched(control);
      return;
    }
    control.updateValueAndValidity({ onlySelf: true });
    control.markAsTouched();
    control.markAsDirty();
  });
}
