import {
  Component,
  ComponentRef,
  ElementRef,
  NgZone,
  OnDestroy,
  reflectComponentType,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { isElementInViewport, viewportVisibilityChange } from '@digistore/ds24-ui/rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { OrderformIframeService } from '../../services/orderform-iframe.service';
import { camelize, dasherize } from '@angular-devkit/core/src/utils/strings';

@Component({
  selector: 'orderform-wrapper2',
  templateUrl: './wrapper2.component.html',
})
export class Wrapper2Component implements OnDestroy {
  static loadingShift = 0;

  @ViewChild('container', { read: ViewContainerRef })
  private _container: ViewContainerRef;
  private _watchAttributeChanges: MutationObserver;
  private _mapAttributNameToInputName: { [templateName: string]: string } = {};
  private _componentRef: ComponentRef<any>;

  constructor(
    private _element: ElementRef,
    private _ngZone: NgZone,
    private _orderformIframe: OrderformIframeService
  ) {}

  ngAfterViewInit(): void {
    const el = this._element.nativeElement as HTMLElement;
    const tag = el.tagName.toLowerCase();

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (isElementInViewport(this._element.nativeElement, 300) || this._orderformIframe.iframeActive) {
          this._ngZone.run(() => this._loadComponent(tag));
          return;
        }
        viewportVisibilityChange(this._element.nativeElement, 300)
          .pipe(
            debounceTime(10),
            filter((visible) => visible),
            take(1)
          )
          .subscribe(() => {
            this._ngZone.run(() => this._loadComponent(tag));
          });
      }, Wrapper2Component.loadingShift++ * 50);
    });
  }

  private async _loadComponent(tag) {
    let importComponent;
    let componentMetaData;
    switch (tag) {
      case 'ds-orderform-payment-plan-changes':
        this._loadComponentByType(
          (await import('../../widgets/payment-plan-changes/payment-plan-changes.component'))
            .PaymentPlanChangesComponent
        );
        break;
      case 'ds-orderform-buy-button':
        this._loadComponentByType(
          (await import('../../widgets/buy-button-wrapper/buy-button-wrapper.component')).BuyButtonWrapperComponent
        );
        break;
      case 'ds-elevio-start':
        this._loadComponentByType((await import('@ds24-ui-components/elevio')).ElevioComponent);
        break;
      /* istanbul ignore next: not testable */
      default:
        throw new Error('custom element ' + tag + ' has no mapping to load');
    }

    const el = this._element.nativeElement as HTMLElement;

    this._watchAttributeChanges = new MutationObserver((mutationsList) => {
      this._ngZone.run(() => {
        this._onAttributeChanges(mutationsList);
      });
    });

    this._watchAttributeChanges.observe(el, {
      subtree: false,
      childList: false,
      attributes: true,
    });

    for (let i = 0; i < el.attributes.length; i++) {
      const attr = el.attributes[i];
      if (!this._mapAttributNameToInputName[attr.name]) {
        continue;
      }

      this._componentRef.setInput(this._mapAttributNameToInputName[attr.name], attr.value);
    }
  }

  private _loadComponentByType<C>(component: Type<C>) {
    this._container.clear();
    this._componentRef = this._container.createComponent(component);
    const componentMetaData = reflectComponentType(component);
    this._mapAttributNameToInputName = componentMetaData.inputs.reduce((obj, item) => {
      obj[item.templateName] = item.templateName;
      const camelCaseName = camelize(item.templateName);
      obj[camelCaseName] = item.templateName;
      obj[dasherize(camelCaseName)] = item.templateName;
      return obj;
    }, {});
  }

  public _onAttributeChanges(mutationsList: MutationRecord[]) {
    const attributeChanges = mutationsList.filter((record) => record.type === 'attributes');
    if (attributeChanges.length === 0) {
      return;
    }

    attributeChanges.forEach((change) => {
      if (!this._mapAttributNameToInputName[change.attributeName]) {
        return;
      }
      this._componentRef.setInput(
        this._mapAttributNameToInputName[change.attributeName],
        (change.target as HTMLElement).getAttribute(change.attributeName)
      );
    });
  }

  public ngOnDestroy() {
    if (this._watchAttributeChanges) {
      this._watchAttributeChanges.disconnect();
    }
  }
}
