import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  InterfaceConversionToolsSetting,
  InterfaceCurrency,
  InterfacePaymentPlan,
  InterfaceUser,
} from './genericDataInterfaces';
import {
  InterfaceDashboard,
  InterfaceDashboardCSVExport,
  InterfaceDashboardWidget,
} from './genericDataInterfaces/dashboards';
import { InterfaceGenericeData } from './genericDataInterfaces/base';

export const DS_GENERIC_NOTIFICATION_SYSTEM =
  new InjectionToken<NotificationSystem.Interface>('GenericNotificationSystem');

export const DS_GENERIC_API_CONFIGURATION =
  new InjectionToken<GenericApiConfigurationInterface>(
    'GenericApiConfiguration'
  );

export interface GenericApiConfigurationInterface {
  httpCatchErrorHeader: string;
  undoLabel: string;
  successfulDeletedLabel: string;
  successfulUpdatedLabel: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace NotificationSystem {
  export interface Config {
    subMessage?: string;
    message?: string;
    actions?: Array<{
      label: string;
      key: string;
    }>;
    duration?: number;
    panelClass?: string;
    close?: boolean;
  }

  interface InterfaceToastResult {
    afterClosed(): Observable<string>;
  }

  export interface Interface {
    info(message: string, customSetting?: Config): InterfaceToastResult;

    success(message: string, customSetting?: Config): InterfaceToastResult;

    warn(message: string, customSetting?: Config): InterfaceToastResult;

    error(message: string, customSetting?: Config): InterfaceToastResult;
  }
}

export declare type SortDirection = 'asc' | 'desc' | '';

export interface GroupedOption<T> {
  key: string;
  label: string;
  options: T[];
}

export interface InterfaceProductGroup extends InterfaceGenericeData {
  id: number;
  name: string;
}

export interface InterfaceDevice extends InterfaceGenericeData {
  id: number;
  name: string;
}

export type ProductApprovalStati = 'approved' | 'pending' | 'new' | 'rejected';

export interface Merchant extends InterfaceGenericeData {
  id: number;
  username: string;
  createdAt: Date;
  modifiedAt: Date;
}

export interface ProductType extends InterfaceGenericeData {
  id: number;
  nameDe: string;
  nameEn: string;
  createdAt: Date;
  modifiedAt: Date;
}

export interface ProductGroup extends InterfaceGenericeData {
  id: number;
  name: string;
  createdAt: Date;
  modifiedAt: Date;
}

export interface ProductApproval extends InterfaceGenericeData {
  approvalStatus: string;
  approvalRejectReason: string;
  approvalNote: string;
  siteownerId: number;
}

export interface InterfaceProduct extends InterfaceGenericeData {
  id: string; // need fix interface... it is a number...

  nameIntern: string;
  merchant: Merchant;
  productType: ProductType;
  productGroup: ProductGroup;
  note: string;
  tag: string;
  salespageUrl: string;
  thankyouPageUrl: string;
  thankyouPageUrl2: string;
  autoAcceptAffiliations: boolean;
  languages: string[];
  maxApprovalStatus: string;
  maxQuantity: number;
  approvals: ProductApproval[];
  createdAt: Date;
  modifiedAt: Date;
  isActive: boolean;
  testPayParameter?: string; // only available on Bulk fetch products
}

export interface InterfacePagebuilderPage extends InterfaceGenericeData {
  id: number;
  name: string;
  active: boolean;
  createdAt: string;
  previewUrl: string;
  externalPreviewUrl: string;
  pageBuilderUrl: string;
}

export interface InterfaceUserSetting extends InterfaceGenericeData {
  id: string;
  settings?: any;
  value?: string | number | boolean;
}

export interface InterfaceGenericEntity {
  successfulDeletedLabel?: string;
  undoActionLabel?: string;
  successfulUpdatedLabel?: string;
  type: GenericDataTypeKey;
  filterFieldList: InterfaceGenericFilterOption[];
  cache?: {
    idEnabled?: true;
    listEnabled?: true;
  };
  migrateData?: (item: GenericDataTypes) => GenericDataTypes;
}

export interface InterfaceGenericFilterSource {
  type: 'generic-api' | 'generic-api-options' | 'values-list' | 'store';
  valuesList?: string[];
  select?: (state: any) => any;
  dataType?: GenericDataTypeKey;
  labelFields?: string[];
  optionLabelFields?: string[];
  params?: { [key: string]: string };
  keyField?: string;
  /**
   * if keyField is not @id property set
   * keyFieldIsNotAGenericReference = true
   * in order to display a label
   * for the filter tag list.
   */
  keyFieldIsNotAGenericReference?: boolean;
  labelTranslationMap?: { [key: string]: string };
  options?: GenericApiFetchListOptions;
  typeSelectMapping?: (rawResults, language: string) => GroupedOption<any>[];
}

export interface InterfaceGenericFilterOption {
  field: string;
  description: string;
  type: 'text' | 'boolean' | 'select' | 'multiSelect' | 'number' | 'date';
  validation?: {
    maxlength?: number;
    required?: boolean;
  };
  default?: true;
  source?: InterfaceGenericFilterSource;
  value?: string | number | boolean;
  values?: {
    key: string;
    value: string | boolean;
  }[];
}

// Interface of applied filters of the filter form
export interface InterfaceDataFilterValues {
  [name: string]: string | number | boolean | any[] | any;
}

export interface InterfaceSavedFilterSetting extends InterfaceGenericeData {
  id: string;
  settings: {
    [savename: string]: InterfaceDataFilterValues;
  };
}

export interface InterfaceCountry extends InterfaceGenericeData {
  id: number;
  code: string; // "AL"
  continent: string;
  nameTranslated: string;
  topPosForLangs: string[];
}

export enum EnumCustomDomainUsage {
  onlyMe = 'vendor',
  meAndAllAffiliates = 'all',
  meAndAffiliatesWhitelist = 'vendor_affiliates_selected_whitelist',
  meAndAffiliatesBlacklist = 'vendor_affiliates_selected_blacklist',
  onlyRedirect = 'redirect',
}

export enum EnumCustomDomainStatus {
  pending = 'pending',
  error = 'error',
  success = 'success',
  failed = 'failed',
}

export interface InterfaceAddCustomDomainData {
  domain: string;
  usage: EnumCustomDomainUsage;
  fallbackProduct: string;
  redirectDomain?: string;
  affiliates?: string[];
}

export interface InterfaceCustomDomain
  extends InterfaceAddCustomDomainData,
    InterfaceGenericeData {
  id: number;
  merchant: string;
  createdAt: Date;
  createdByUser: string;
  modifiedByUser: string;
  modifiedAt: Date;
  isActive: boolean;
  $selected: boolean;
  dnsDomain?: string;
  dnsDestination?: string;
  status?: EnumCustomDomainStatus;
}

export interface InterfaceDiscountCode extends InterfaceGenericeData {
  id: number;
  merchant: {
    id: number;
    username: string;
    createdAt: Date;
    modifiedAt: Date;
  };
  code: string;
  discount: number;
  products: any[];
  hasAllProducts: boolean;
  firstAmount: number;
  otherAmounts: number;
  otherDiscounts: number;
  upgradePolicy: string;
  isCountLimited: boolean;
  countLeft: number;
  note: string;
  isAffiliateKeptForUpgrades: boolean;
  campaignkey: string;
  isPaidByAffiliate: boolean;
  isActive: boolean;
  currency: {
    id: number;
    code: string;
    createdAt: Date;
    modifiedAt: Date;
  };
}

export type GenericDataTypes =
  | InterfaceProductGroup
  | InterfaceProduct
  | InterfacePagebuilderPage
  | InterfaceUserSetting
  | InterfaceDashboard
  | InterfaceDashboardWidget
  | InterfaceCurrency
  | InterfaceCountry
  | InterfaceDiscountCode
  | InterfaceDashboardCSVExport.InterfaceDashboardReportTemplate
  | InterfaceDashboardCSVExport.InterfaceDashboardReport
  | InterfaceGenericeData
  | InterfaceConversionToolsSetting
  | InterfaceUser
  | InterfacePaymentPlan;

export enum GenericDataTypeKey {
  Users = 'users',
  UserSettings = 'user_option',
  Products = 'products',
  PageBuilderPages = 'pages',
  CustomDomains = 'domains',
  DiscountCodes = 'discount_vouchers',
  ProductGroups = 'product_groups',
  Dashboards = 'dashboards',
  DashboardWidgets = 'dashboard_widgets',
  ProductTypes = 'product_types',
  SiteOwners = 'siteowners',
  Currencies = 'currencies',
  Countries = 'countries',
  Orderforms = 'orderforms',
  Devices = 'devices',
  OS = 'os',
  DashboardReportsTemplates = 'report_templates',
  DashboardReports = 'reports',
  Upgrades = 'upgrades',
  UpgradeTypes = 'upgrade_types',
  CustomForms = 'custom_forms',
  DeliveryNotes = 'delivery_notes',
  /**
   * aktuell verwenden wir die PromoPages nicht
   * deshalb auskommentiert.
   * Weil das Backend die Resource nun promolinks genannt hat
   */
  //PromoPages = 'promo_pages',
  PromoLinks = 'promolinks',
  SalesPages = 'salespages',
  Images = 'images',
  FileVaults = 'file_vaults',
  SmartUpgrades = 'smart_upgrades',
  SocialProof = 'social_proofs',
  Countdowns = 'countdowns',
  ConversionToolsSettings = 'conversion_tools_settings',
  PaymentPlans = 'payment_plans',
  PaymentProviders = 'payment_providers',
  ProductCategories = 'product_categories',
  DeliveryTypes = 'delivery_types',
  Communities = 'communities',
  OrderformTemplates = 'orderform_templates',
  UpsellWidgets = 'upsell_widgets',
}

export interface DataServiceState<T> {
  items: T[];
  count: number;
  page: number;
  itemsPerPage: number;
  loadTime: number;
  options: GenericApiFetchListOptions;
  save: {
    pending: boolean;
    success: boolean;
    error: boolean;
  };
  fetch: {
    pending: boolean;
    success: boolean;
    error: boolean;
  };
  delete: {
    pending: boolean;
    success: boolean;
    error: boolean;
  };
  sort: {
    pending: boolean;
    success: boolean;
    error: boolean;
  };
}

export interface DataIdCacheState {
  fetch: {
    pending: boolean;
    error: false | HttpErrorResponse;
  };
  save: {
    pending: boolean;
    error: false | HttpErrorResponse;
    violations?: GenericApiViolationError[];
  };
  loadTime?: number;
  data: GenericDataTypes;
}

export const USER_SETTINGS_STATE_KEY = GenericDataTypeKey.UserSettings;
export const GENERIC_DATA_ENDPOINT = '/v2/api/generic/';

export interface GenericApiViolationError {
  code: string;
  message: string;
  propertyPath: string;
}

export interface GenericApiGetResponse<T> {
  '@context': string;
  '@id': string;
  '@type': string;
  'hydra:member': T[];
  'hydra:totalItems': number;
}

export enum GenericBlobMimeType {
  CSV = 'text/csv',
  XSLX = 'application/vnd.ms-excel',
}

export interface GenericApiCachePersist {
  store(
    key: string,
    data: DataServiceState<GenericDataTypes> | DataIdCacheState
  );
}

export interface GenericApiFetchListOptions extends GenericApiFetchOptions {
  sort?: { column: string; direction: SortDirection }[];
  filter?: InterfaceDataFilterValues;
}

export interface GenericApiFetchOptions {
  cache?: {
    persist?: GenericApiCachePersist;
    type?: 'state';
    maxAgeSeconds?: number;
  };
  catchError?: boolean;
}

export interface GenericDataState {
  list: {
    [dataKey: string]: DataServiceState<GenericDataTypes>;
  };
  byId: {
    [refId: string]: DataIdCacheState;
  };
}

export interface GenericState {
  genericData: GenericDataState;
}

export interface GenericOptionListItem {
  value: string;
  label: string;
  group?: string;
  groupId?: string | number;
  divider?: boolean;
}

interface GenericErrorResponseViolation {
  code: string;
  message: string;
  propertyPath: string;
}

export function buildGenericErrorResponse(
  error: HttpErrorResponse
): HttpErrorResponse {
  if (error.status === 422 && error.error['@type']) {
    return new HttpErrorResponse({
      ...error,
      error: {
        detail: error.error['hydra:description'],
        title: error.error['hydra:title'],
        type: error.error['@type'],
        violations: error.error['violations'],
      },
    });
  }

  return error;
}

export interface GenericErrorResponseJson {
  detail: string;
  title: string;
  type: string;
  violations: GenericErrorResponseViolation[];
}

export interface GenericErrorResponseJsonLd {
  '@context': string;
  '@type': string;
  'hydra:description': string;
  'hydra:title': string;
  violations: GenericErrorResponseViolation[];
}

export type GenericErrorResponseJsonMergePatch = GenericErrorResponseJson;
